$(document).ready(function() {
    $('.headline-quote').cycle({
        'fx': 'fade',
        'speed': 500
    });

    $('#screenshots-block').cycle({
        'fx': 'fade',
        'speed': 500
    });

    var $iframe = $('<iframe src="https://www.youtube-nocookie.com/embed/Va6L0MFbZQI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>');

	if (isiPhone()) {
//		document.body.parentElement.className = "is-iphone";
//		document.body.className = "is-iphone";
        $('body').append('<meta name="viewport" content="width=device-width;" />');

        $iframe.attr('width', '536');
        $iframe.attr('height', '302');

	} else if (isiPad()) {
		document.body.parentElement.className = "is-ipad";
        document.body.className = "is-ipad";
        
        $('body').append('<meta name="viewport" content="initial-scale=0.9;" />');

        $iframe.attr('width', '536');
        $iframe.attr('height', '302');
        //$iframe.attr('width', '458');
        //$iframe.attr('height', '258');

	} else {
		document.body.parentElement.className = "is-desktop";
		document.body.className = "is-desktop";
        
        $iframe.attr('width', '536');
        $iframe.attr('height', '302');
        //$iframe.attr('width', '458');
        //$iframe.attr('height', '258');
	}

    setTimeout(function() {
        $('#iphone').append($iframe);
    }, 0.5 * 1000);

    var desiredLaunchTime = 1357693200;
    var foo = new Date();
    var timeDiff = parseFloat( (0 * 24 * 60 * 60) + (foo.getTime())/1000) < parseFloat(desiredLaunchTime);
    if (timeDiff) {
        /*
        $('#purchase').html('<p class="coming-soon">Coming Jan 10th</p>');
        */
    }
});

function isiPhone(){
    return (
        (navigator.platform.indexOf("iPhone") != -1) ||
        (navigator.platform.indexOf("iPod") != -1)
	);
}

function isiPad() {
	return (
		(navigator.platform.indexOf("iPad") != -1)
    );
}

